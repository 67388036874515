<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>

<template>
  <div class="page__wrapper">
    <simple-table
      :table-column="tableColumn"
      :table-data="tableData"
      :table-page-info="tablePageInfo"
      :table-data-count="tableDataTotalCount"
      @page-info-change="handlePageInfoChange"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            v-permission="'config_kpi:add'"
            type="primary"
            @click="handleAddClick"
          >
            {{ $t('operation.create') }}
          </el-button>
        </template>
      </simple-form>
      <template
        slot="isJoin"
        slot-scope="scope"
      >
        {{ $getDictLabel({type: 'yes_no', value: scope.row.isJoin}) }}
      </template>
      <template
        slot="isSpecial"
        slot-scope="scope"
      >
        {{ $getDictLabel({type: 'yes_no', value: scope.row.isSpecial}) }}
      </template>
      <template
        slot="businessArea"
        slot-scope="scope"
      >
        {{ $getDictLabel({type: 'mt_kpi_business_area', value: scope.row.businessArea}) }}
      </template>
      <template
        slot="operation"
        slot-scope="scope"
      >
        <el-button
          v-permission="'config_kpi:view'"
          circle
          icon="el-icon-search"
          :title="$t('operation.view')"
          @click="handleViewClick(scope.row)"
        />
        <el-button
          v-permission="'config_kpi:edit'"
          circle
          icon="el-icon-edit"
          :title="$t('operation.edit')"
          @click="handleEditClick(scope.row)"
        />
        <el-button
          v-permission="'config_kpi:delete'"
          circle
          icon="el-icon-delete"
          :title="$t('operation.delete')"
          @click="deleteRecord(scope.row)"
        />
      </template>
    </simple-table>
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
  </div>
</template>
<script>
import pageMixins from '@/components/base/simple-table/mixin'
import detailDialog from './detail'
const BASEURL = {
  list: '/jlr/mt/kpi/list',
  delete: '/jlr/mt/kpi/delete'
}
export default {
  name: 'ConfigKip',
  components: { detailDialog },
  mixins: [pageMixins],
  data () {
    return {
      rowData: {},
      tableData: [],
      searchModel: {},
      isViewFlag: false,
      isShowDetailDialog: false
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'name',
          type: 'Input',
          labelWidth: '50px',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('config_kpi.name'),
          component: { clearable: true }
        },
        {
          prop: 'code',
          type: 'Input',
          col: { xs: 5, sm: 5, md: 5 },
          label: this.$t('config_kpi.code'),
          component: { clearable: true }
        },
        {
          prop: 'isJoin',
          type: 'Select',
          labelWidth: '110px',
          col: { xs: 6, sm: 6, md: 6 },
          label: this.$t('config_kpi.isJoin'),
          component: { clearable: true, optionList: this.$getDictList('yes_no') }
        },
        {
          prop: 'isSpecial',
          type: 'Select',
          labelWidth: '110px',
          col: { xs: 6, sm: 6, md: 6 },
          label: this.$t('config_kpi.isSpecial'),
          component: { clearable: true, optionList: this.$getDictList('yes_no') }
        },
        {
          slotName: 'tableOperation',
          labelWidth: '0',
          style: { textAlign: 'right' },
          col: { xs: 3, sm: 3, md: 3 }
        }
      ]
    },
    tableColumn () {
      return [
        { prop: 'name', label: this.$t('config_kpi.name'), minWidth: 120 },
        { prop: 'code', label: this.$t('config_kpi.code'), minWidth: 120 },
        { prop: 'fluctuateChangeRateRule', label: this.$t('config_kpi.fluctuationRange'), minWidth: 120 },
        { prop: 'thresholdChangeRateRule', label: this.$t('config_kpi.thresholdRange'), minWidth: 120 },
        { prop: 'thresholdAbsoluteValue', label: this.$t('config_kpi.fluctuationAbsoluteValue'), minWidth: 120 },
        { prop: 'isJoin', label: this.$t('config_kpi.isJoin'), minWidth: 100, slotName: 'isJoin' },
        { prop: 'isSpecial', label: this.$t('config_kpi.isSpecial'), minWidth: 120, slotName: 'isSpecial' },
        { prop: 'businessArea', label: this.$t('config_kpi.businessArea'), minWidth: 100, slotName: 'businessArea' },
        { prop: 'calculationFormula', label: this.$t('config_kpi.calculationFormula'), minWidth: 380 },
        { prop: 'sort', label: this.$t('config_kpi.sort'), minWidth: 60 },
        { label: this.$t('field.operation'), slotName: 'operation', fixed: 'right', width: 100 }

      ]
    }
  },
  provide () {
    return { handleDataSearch: this.handleDataSearch }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const searchInfo = this.$utils.parseQueryCondition(this.searchModel, this.searchFormField)
      this.tablePageInfo.orderBy = 'sort'
      const params = Object.assign({}, { page: this.tablePageInfo }, searchInfo)
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
          this.tablePageInfo = { pageNo: respData.pageNo, pageSize: respData.pageSize }
          this.tableDataTotalCount = respData.count
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      this.rowData = {}
      this.isViewFlag = false
      this.isShowDetailDialog = true
    },
    handleViewClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = true
      this.isShowDetailDialog = true
    },
    handleEditClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = false
      this.isShowDetailDialog = true
    },
    deleteRecord (row) {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    }
  }
}
</script>
